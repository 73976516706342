#table_pagination {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
    border: 1px solid #0075a0;
    line-height: 1.5715;
  }
  .ant-pagination {
    border: 1px solid #0075a0;
  }
}
