#error-boundary-page {
  background-position: right center;
  background-size: 50% 80%;
  background-repeat: no-repeat;
  width: 100vw;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 5rem;
    color: @primary-color;
    margin-bottom: 1rem;
  }

  h2,
  h5 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: rem;
  }
  h2 {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) {
    h1 {
      font-size: 8rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
}
