@pagination-item-bg: @light-color;
@pagination-item-color: #7e8299;

.ant-pagination {
  & &-item {
    background-color: @pagination-item-bg;
    border-color: @pagination-item-bg;

    a {
      color: @pagination-item-color;
      font-size: 0.8rem;
    }

    &:hover,
    &:active,
    &.ant-pagination-item-active {
      background-color: @primary-color;
      border-color: @primary-color;

      a {
        color: @white;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: @pagination-item-bg;
      border-color: @pagination-item-bg;
      color: @pagination-item-color;
    }

    &:hover,
    &:focus {
      .ant-pagination-item-link {
        background-color: @primary-color;
        border-color: @primary-color;
        color: @white;
      }
    }
  }

  & &-disabled {
    .ant-pagination-item-link {
      background-color: @pagination-item-bg;
      border-color: @pagination-item-bg;
      color: @pagination-item-color;
    }

    &:hover,
    &:focus {
      .ant-pagination-item-link {
        background-color: @pagination-item-bg;
        border-color: @pagination-item-bg;
        color: @pagination-item-color;
      }
    }
  }

  & &-options {
    & .ant-select {
      .ant-select-selector {
        background-color: @pagination-item-bg;
        border-color: @pagination-item-bg;
      }
    }
  }
}
