.table_detail {
  .ant-table-container {
    border-radius: 5px;
    padding: 20px 0px;
    .ant-table-thead > tr > th {
      background: transparent;
      color: var(--orange-color);
      border-bottom: 0px dashed @light-primary-color !important;
      font-size: 15px;
    }

    .ant-table-content > table > thead > tr > th,
    .ant-table-content > table > tbody > tr > td {
      border-right: 1px dashed @light-primary-color !important;
      padding: 10px;
      border-top: 1px dashed @light-primary-color !important;
    }
    .ant-table-content > table > thead > tr > th {
      border-top: 0px dashed @light-primary-color !important;
    }
    .ant-table-content > table > thead > tr > th:last-child,
    .ant-table-content > table > tbody > tr > td:last-child {
      border-right: 0px !important;
    }
    .ant-table-tbody {
      .content-left {
        text-align: start !important;
      }
      .ant-table-cell {
        border: 0px;
      }
    }
  }

  > .table_style2 {
    .ant-table-container {
      .ant-table-content > table > thead > tr > th,
      .ant-table-content > table > tbody > tr > td {
        border: 0px dashed @light-primary-color !important;
        border-left: 1px dashed @light-primary-color !important;
        padding: 10px;
        vertical-align: top;
      }

      .ant-image {
        width: 100%;
      }
    }
  }
}
