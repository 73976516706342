@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "./additional-variables.less";
@import "../layouts/index.less";
@import "~bootstrap-utilities";
@import "../utilities/index.less";
@import "../components/index.less";
@import "./base-variables.less";
@import "../pages/index.less";
@import "../typography.less";
@import "~react-perfect-scrollbar/dist/css/styles.css";

// Layout
@layout-header-background: @primary-color;
@layout-text-color: @white;

:root {
  --primary-color: @primary-color;
  --light-color: @light-color;
  --orange-color: @orange-color;
}

// Sidebar
@sidebar-bg-color: @white;
@sidebar-text-color: @black;

@sidebar-submenu-color: @black;
@sidebar-submenu-hover-color: @black;
@sidebar-submenu-hover-bg-color: @light-color;
@sidebar-submenu-active-color: @primary-color;
@sidebar-submenu-active-bg-color: @white;

@sidebar-menu-item-bg-color: @light-color;
@sidebar-menu-item-hover-bg-color: @light-color;
@sidebar-menu-item-color: @black;
@sidebar-menu-item-active-color: @primary-color;
@sidebar-menu-item-icon-color: @black;
@sidebar-menu-item-icon-active-color: @primary-color;
@sidebar-menu-item-icon-hover-color: @black;

// Tooltip
@tooltip-bg: @white;
@tooltip-color: @black;

@primary-color: #136cbf;@border-radius-base: 6px;