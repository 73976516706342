.ant-divider {
  &.ant-divider-horizontal {
    &.primary {
      color: @primary-color;
      border-top: 2px solid @light-primary-color;

      &.ant-divider-with-text {
        border-top: 0;

        &::before,
        &::after {
          border-top-width: 2px;
          border-top-color: @light-primary-color;
        }
      }
    }
  }
}
