.ant-card {
  overflow: hidden;
  &.ant-card-shadow {
    box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.15);
  }
  &.no-border {
    .ant-card-head {
      border-bottom: 0 !important;
    }
  }
  &.no-padding-top {
    .ant-card-body {
      padding-top: 0 !important;
    }
  }
  &.no-padding-bottom {
    .ant-card-body {
      padding-bottom: 0 !important;
    }
  }
  &-inner {
    background-color: @light-color !important;
  }
}
