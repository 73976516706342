body {
  font-weight: 300;
  font-family: Roboto;
}

// Page title
.ant-page-header-heading-title {
  font-weight: 400 !important;
  color: #0090c6;
}
