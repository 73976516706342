.ant-table-wrapper {
  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-tbody,
  .ant-table-thead,
  .ant-table-tfoot {
    > tr > th,
    > tr > td {
      vertical-align: baseline;
      font-size: 13px;
    }
  }

  .ant-table-thead tr th.filter-cell {
    background-color: #f3f7ff;
  }
}
