@btn-warning-color: @warning-color;
@btn-success-color: @success-color;
@btn-danger-color: @error-color;
@btn-light-color: @light-color;

.gen-btn-round(@size) {
  &.ant-btn-group-round {
    .ant-btn:first-child {
      border-top-left-radius: @size !important;
      border-bottom-left-radius: @size !important;
    }
    .ant-btn:last-child {
      border-top-right-radius: @size !important;
      border-bottom-right-radius: @size !important;
    }
  }
}

// Group
.ant-btn-group {
  max-width: 100%;
  overflow: auto;
  .gen-btn-round(@btn-circle-size);

  &-sm {
    .gen-btn-round(@btn-circle-size-sm);
  }

  &-lg {
    .gen-btn-round(@btn-circle-size-lg);
  }
}
// End group

.ant-btn {
  display: inline-flex !important;
  align-items: center;
  border-radius: 6px;

  // Icon
  &.ant-btn-icon-only {
    justify-content: center;
  }

  > svg {
    width: 19px;

    + span {
      margin-left: 8px;
    }
  }

  &.ant-btn-lg {
    > svg {
      width: 21px;
    }
  }

  &.ant-btn-icon-right {
    flex-direction: row-reverse;

    > .anticon + span,
    > svg + span {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  // End Icon

  &:not([disabled]) {
    &.ant-btn {
      &.ant-btn-warning {
        border-color: @btn-warning-color;
        color: @btn-warning-color;

        &:hover,
        &:focus {
          border-color: lighten(@btn-warning-color, 5%);
          color: lighten(@btn-warning-color, 5%);
        }
      }

      &.ant-btn-success {
        border-color: @btn-success-color;
        color: @btn-success-color;

        &:hover,
        &:focus {
          border-color: lighten(@btn-success-color, 5%);
          color: lighten(@btn-success-color, 5%);
        }
      }

      &-primary {
        color: @white !important;

        &.ant-btn-warning {
          background: @btn-warning-color;
          border-color: @btn-warning-color;

          &:hover,
          &:focus {
            background: lighten(@btn-warning-color, 5%);
            border-color: lighten(@btn-warning-color, 5%);
          }
        }

        &.ant-btn-success {
          background: @btn-success-color;
          border-color: @btn-success-color;

          &:hover,
          &:focus {
            background: lighten(@btn-success-color, 5%);
            border-color: lighten(@btn-success-color, 5%);
          }
        }
      }

      &-text {
        border-color: transparent !important;

        &.ant-btn-warning {
          border-color: transparent;
          color: @btn-warning-color;

          &:hover,
          &:focus {
            border-color: transparent;
          }
        }

        &.ant-btn-success {
          border-color: transparent;
          color: @btn-success-color;

          &:hover,
          &:focus {
            border-color: transparent;
          }
        }
      }

      &-link {
        background: transparent;
        border-color: transparent;

        &.ant-btn-warning {
          background: transparent;
          border-color: transparent;
          color: @btn-warning-color;

          &:hover,
          &:focus {
            border-color: transparent;
          }
        }

        &.ant-btn-success {
          background: transparent;
          border-color: transparent;
          color: @btn-success-color;

          &:hover,
          &:focus {
            border-color: transparent;
          }
        }
      }

      &-light {
        background: @btn-light-color !important;
        border-color: @btn-light-color !important;
        color: darken(@btn-light-color, 40%) !important;

        &:hover,
        &:focus {
          background-color: darken(@btn-light-color, 5%) !important;
          border-color: darken(@btn-light-color, 5%) !important;
        }

        &.ant-btn-success {
          color: darken(@btn-success-color, 5%) !important;
        }

        &.ant-btn-warning {
          color: darken(@btn-warning-color, 5%) !important;
        }

        &.ant-btn-dangerous {
          color: darken(@btn-danger-color, 5%) !important;
        }
      }

      &-hover {
        &-light {
          &:hover,
          &:focus {
            background-color: @btn-light-color !important;
            border-color: @btn-light-color !important;
            color: @primary-color !important;
          }
        }
        &-underline {
          &:hover,
          &:focus {
            text-decoration: underline !important;
          }
        }

        &-primary {
          &:hover,
          &:focus {
            background-color: @btn-primary-bg !important;
            border-color: @btn-primary-bg !important;
            color: @white !important;
          }
        }

        &-success {
          &:hover,
          &:focus {
            background-color: @btn-success-color !important;
            border-color: @btn-success-color !important;
            color: @white !important;
          }
        }
        &-warning {
          &:hover,
          &:focus {
            background-color: @btn-warning-color !important;
            border-color: @btn-warning-color !important;
            color: @white !important;
          }
        }
        &-danger {
          &:hover,
          &:focus {
            background-color: @btn-danger-color !important;
            border-color: @btn-danger-color !important;
            color: @white !important;
          }
        }
      }
    }
  }
}
