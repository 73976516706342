@input-border-radius: @border-radius-base;
@input-bg-color: @white;
@input-border-color: #0075a0;

.ant-input-affix-wrapper {
  border-radius: @input-border-radius;
  background-color: @input-bg-color;
  border-color: @input-border-color;

  input {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: @input-bg-color;
  }
}

.ant-input {
  background-color: @input-bg-color;
  border-radius: @input-border-radius;
  border-color: @input-border-color;

  &.input-filter {
    border-radius: 2px;
  }
}

.ant-form-item-explain {
  font-size: @font-size-base - 2px;
  z-index: 1;
}

.ant-select.input-filter:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px;
  border-color: @input-border-color;
}
