html body {
  font-family: Tahoma;
}

.ant-layout {
  background-color: #f7f7fb;

  > .ant-layout-content {
    margin-top: 8px;
    margin-left: 0;
    background-color: white;
    box-shadow: 3px 3px 7px 2px #e2dfdf;
  }

  .ant-page-header-heading-sub-title {
    display: none;
  }
}

.ant-page-header-heading-title {
  color: #0090c6;
  line-height: 47px;
  font-size: 20px;
}

.form-control-custom {
  .ant-picker,
  .ant-input-affix-wrapper {
    border-radius: 4px;
    border-color: #0075a0;
  }
}

.ant-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #0090c6;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(50deg) scale(1) translate(-50%, -50%);
  border: 1px solid #2ab930;
  border-top: 0;
  border-left: 0;
}
.display-block {
  display: block;
}
.ant-table-small {
  font-size: 13px !important;
}
.ant-input,
.ant-select,
.ant-input-password,
.ant-picker-input > input,
.ant-btn {
  font-size: 13px !important;
}
button.ant-switch {
  min-width: 38px;
  height: 20px;
  line-height: 20px;

  > .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 10px;
}
.ant-space-item {
  font-size: 13px;
  .ant-btn > span {
    font-size: 13px;
  }
}
.ant-space-item > .ant-btn.icon-btn {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.line-end {
  display: flex;
  justify-content: flex-end;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pd-card-table {
  padding: 20px 10px !important;
}

.text-size-13 {
  font-size: 13px !important;
}

/*Center*/
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*SELECT*/
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  align-items: center;
}

/*UPLOAD*/
.ant-upload-list-picture-card-container {
  margin: 0;
}

/*Modal*/
.modal-customize {
  width: 98% !important;
  top: 2% !important;
  .ant-modal-title {
    color: #0090c6;
    line-height: 40px;
    font-size: 20px;
    font-weight: 400 !important;
    margin-left: 20px;
  }

  .ant-modal-header {
    border-bottom: 0px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  /*Tab modal*/
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #0075a0;
    border-radius: 4px 4px 0 0 !important;
  }
  .ant-tabs-card.ant-tabs-top
    > .ant-tabs-nav
    .ant-tabs-tab-active
    .ant-tabs-tab-btn,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0px 0px 0 0;
    border: 0px solid #ffffff;
  }
  .ant-tabs-card.ant-tabs-top
    > .ant-tabs-nav
    .ant-tabs-tab:nth-of-type(even)
    .ant-tabs-tab-btn,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:nth-of-type(even)
    .ant-tabs-tab-btn {
    border-left: 1px solid #0075a0;
    border-right: 1px solid #0075a0;
  }
  .ant-tabs-card.ant-tabs-top
    > .ant-tabs-nav
    .ant-tabs-tab:nth-of-type(even):hover
    .ant-tabs-tab-btn,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:nth-of-type(even):hover
    .ant-tabs-tab-btn {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin-left: -1px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
  .ant-tabs-tab-btn {
    color: #0075a0;
    padding: 0px 16px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .ant-tabs-tab {
    font-size: 14px;
  }
  .ant-tabs-tab:hover {
    background: #0075a0 !important;
    border-radius: 4px 4px 0 0 !important;
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active,
    .ant-tabs-tab-btn,
    .ant-tabs-tab-remove {
      color: white;
    }
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: transparent;
    padding: 7.5px 0px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
}
.ant-modal-header {
  border: 0;
}
.ant-modal-title {
  color: #0090c6;
}

.ant-modal-footer {
  text-align: center;
  border-top: 0 !important;
}

.iframe-class {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 #eaeaea;
  margin-top: 5px;
}

/*Card*/
.color_title {
  .ant-card-head-title {
    color: #0075a0;
  }
}
.extra_right {
  .ant-card-head-title {
    flex: none;
    color: #ff8721;
  }
  .ant-card-extra {
    margin-left: 5px;
  }
}

.modal-customize-xl {
  width: 1200px !important;
}
.modal-customize-md {
  width: 800px !important;

  .ant-modal-title {
    color: #0090c6;
    line-height: 40px;
    font-size: 20px;
    font-weight: 400 !important;
    margin-left: 20px;
  }

  .ant-modal-header {
    border-bottom: 0px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .ant-modal-footer {
    text-align: center;
    border-top: 0px;
  }
}

/*Table*/
.table-customize {
  @color-main: #0090c6;
  @color-font: white;

  .ant-table-thead > tr > th {
    background-color: @color-main;
    padding: 10px 16px;
    color: @color-font;
    font-weight: 500;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #ecf0f3;
    height: 2.6em;
    width: 0em;
  }

  .ant-table-thead > tr > th {
    // border: 0.01em solid #ecf0f3;
    border: 0;
  }

  .ant-table-thead > tr > .py-2 {
    background-color: #ecf0f3;
  }

  &.firstFilter .ant-table-tbody > tr.ant-table-row:first-child td {
    border: 0px solid #ecf0f3;
    padding: 10px 16px;
  }
  .ant-table-tbody > tr.ant-table-row > td {
    background-color: white;
    // border: 0.01em solid #ecf0f3;
    border: 0;
    vertical-align: middle;
    > b {
      font-weight: 600;
    }

    > .ant-form-item {
      margin-bottom: 0px;
    }
  }
  .ant-table-tbody > tr.ant-table-row:nth-of-type(odd) > td {
    background-color: #f3f7ff;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-row:nth-of-type(odd):hover > td {
    background-color: #fafafa;
  }
}

.show-border {
  // .ant-table-thead > tr > th {
  //   border: 0.01em solid #ecf0f3 !important;
  // }
  .ant-table-tbody > tr.ant-table-row > td {
    border: 0.5px solid #0090c6 !important;
  }
}
.box-shadow {
  box-shadow: #eaeaea 0px 2px 2px 0px;
  border: 1px solid #f0f0f0;
  margin: 2px;

  .ant-card-body {
    padding: 0 15px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.ant-avatar.avatar {
  width: auto;
  max-width: 260px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.line-customize {
  display: block;
  height: 0px;
  border: 1px solid @light-primary-color;
  margin: 10px 0px;
}

.title_level2 {
  font-size: 15px;
  color: #0075a0;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 6px;
}

.text_status {
  font-weight: bold;
  &.approved {
    color: #0075a0;
  }
  &.unapprove {
    color: #ff0000;
  }
}

.font-weight {
  font-weight: bold;
}

.tag_customize {
  color: #2a3037;
  border: 1px solid #c0bebe;
  background: white;
  padding: 5px 15px;
  border-radius: 15px;
  line-height: 19px;
}
.tag_customize img {
  margin-right: 10px !important;
}

.data-input {
  input,
  .ant-select {
    color: #0075a0 !important;
  }

  input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(0, 117, 160, 0.5) !important;
    border-radius: 2px !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-checkbox + span {
    font-size: 13px;
  }
}
.label-custom .ant-form-item-label label {
  color: var(--orange-color);
  font-size: 15px;
}
.ant-select-item {
  font-size: 13px;
  font-weight: normal;
}

#table_pagination {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #0075a0;
    border-radius: 2px;
  }

  .ant-select-arrow {
    color: #2e3a59;
    font-size: 10px;
  }
}
.ant-select-single.ant-select-open .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #0075a0;
  font-size: 13px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 0px;
}
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  background-color: white;
  border-color: white;
}

.form-color {
  .ant-form-item-label > label {
    color: #ff8721;
  }
}

.data-input-auth {
  .ant-form-item-label > label {
    font-size: 13px;
  }
}

.data-input-get-otp {
  .ant-row.ant-form-item:first-child .ant-col.ant-form-item-control {
    margin-left: 50px;
  }
}

.form-login {
  width: 615px;
  padding: 0px 50px;

  &.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
  }
}

.button-custom {
  background: "#0090C6";
  text-transform: uppercase;
}

.button-custom.ant-btn-primary[disabled],
.button-custom.ant-btn-primary[disabled]:hover,
.button-custom.ant-btn-primary[disabled]:focus,
.button-custom.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
}
