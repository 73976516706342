@white: white;
@layout-primary-color: darken(@primary-color, 10%);
@layout-text-color: @black;
@layout-text-active-color: @primary-color;
@header-background: @layout-header-background;
@header-tab-bg-color: @white;

@sidebar-width: 250px;
@sidebar-bg-color: #0090c6; // lighten(@layout-primary-color, 5%);
@sidebar-text-color: @white;
@sidebar-menu-item-bg-color: @white;
@sidebar-menu-item-hover-bg-color: @white;
@sidebar-menu-item-color: @white;
@sidebar-menu-item-hover-color: #4a90e2;
@sidebar-menu-item-active-color: #4a90e2;
@sidebar-menu-item-icon-color: @white;
@sidebar-menu-item-icon-hover-color: @white;
@sidebar-menu-item-icon-active-color: @primary-color;

@sidebar-submenu-color: lighten(@white, 5%);
@sidebar-submenu-hover-color: @white;
@sidebar-submenu-hover-bg-color: darken(@white, 5%);
@sidebar-submenu-active-color: @white;
@sidebar-submenu-active-bg-color: @primary-color;

@collapsed-sider-width: 80px;
@font-size: 13px;

@content-bg-color: @light-color;

#dashboard-layout {
  min-height: 100vh;

  // Header
  // .ant-layout-header {
  //   z-index: 1000;
  //   border-top-left-radius: @border-radius-base;
  //   border-bottom-left-radius: @border-radius-base;

  //   &.site-layout-background {
  //     background: @header-background;
  //   }

  //   a {
  //     color: @layout-text-color;
  //   }

  //   // Tabs
  //   .layout-tabs {
  //     max-width: calc(100% - 140px);
  //     .ant-tabs {
  //       background-color: @header-tab-bg-color;
  //       padding-left: @padding-md * 2;
  //       border-top-left-radius: @border-radius-base;
  //       border-top-right-radius: @border-radius-base;
  //     }
  //   }

  //   // User dropdown
  //   .ant-dropdown-trigger {
  //     color: @layout-text-color;
  //     font-weight: normal;
  //   }
  // }

  // End header

  // Sidebar
  .ant-layout-sider {
    background-color: #0090c6;
    // background-color: pink;
    &.first-sider,
    &.second-sider {
      overflow: auto;
      height: 100vh;
      position: fixed;
      left: 0;
    }

    &.first-sider {
      border-right: 1px solid @light-color;
      padding-top: 2rem;
    }

    &.second-sider {
      left: 80px;
      padding: 2rem 1.5rem;
      z-index: 1;
    }

    &:not(.ant-layout-sider-collapsed) {
      flex: 0 0 @sidebar-width !important;
      min-width: @sidebar-width !important;
      max-width: @sidebar-width !important;
      width: @sidebar-width !important;
    }

    .logo {
      width: 100%;
      height: auto;
      background: #0090c6;
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 1rem;

      img {
        width: 100%;
      }

      span {
        font-size: x-large;
        font-weight: normal;
        color: @sidebar-text-color;
      }
    }

    .ant-menu {
      background-color: #0090c6;
      border: 0;

      .ant-menu-item-icon {
        font-size: 20px;
      }

      span {
        font-size: @font-size;
        font-weight: 700;
        color: white;
      }

      .ant-menu-submenu {
        margin: 4px auto;
        color: @sidebar-submenu-color;

        .ant-menu-submenu-title {
          .ant-menu-submenu-arrow {
            &::before,
            &::after {
              background-color: @sidebar-submenu-color;
            }
          }

          &:hover {
            color: @sidebar-submenu-hover-color;
          }

          &:active {
            background-color: transparent;
          }
        }

        &.ant-menu-submenu-open,
        &.ant-menu-submenu-active {
          .ant-menu-submenu-title {
            color: @sidebar-submenu-active-color;

            .ant-menu-submenu-arrow {
              &::before,
              &::after {
                background-color: @sidebar-submenu-active-color;
              }
            }

            &:hover {
              color: @sidebar-submenu-active-color;
            }
          }
        }

        .ant-menu-item {
          width: 100%;
        }
      }

      .ant-menu-item {
        // width: calc(100% - 20px);
        margin: 4px auto;
        border-radius: 0px;
        border-width: 0;
        border-right: 5px transparent solid;
        color: white;

        > span > a {
          color: white;
          font-size: @font-size;
          font-weight: 700;
          // font-weight: 500;
        }

        &:hover {
          background-color: white;
          color: #4a90e2;
        }

        &.ant-menu-item-selected {
          background-color: white;
          color: #4a90e2;

          > .anticon {
            color: @sidebar-menu-item-icon-active-color;
          }

          > span > a {
            color: @sidebar-menu-item-active-color;
            // font-weight: 500;
          }

          &::after {
            opacity: 0;
          }
        }

        &.ant-menu-item-active:not(.ant-menu-item-selected) {
          color: #4a90e2;

          > .anticon {
            color: #4a90e2;
          }

          > span > a {
            color: #4a90e2;
            // font-weight: 500;
          }
        }
      }

      // &.ant-menu-inline-collapsed {
      //   > .ant-menu-item {
      //     width: 40px;
      //     padding: 0;
      //     text-align: center;
      //   }
      // }
    }

    .ant-layout-sider-trigger {
      height: 40px !important;
      width: 40px !important;
      background-color: @primary-color;
      border-radius: @border-radius-base;
      left: calc(@sidebar-width);
      bottom: 40px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.ant-layout-sider-collapsed {
      .ant-layout-sider-trigger {
        left: @collapsed-sider-width;
      }

      &.second-sider {
        width: 0 !important;
        min-width: 0 !important;
        padding: 0;
      }

      & + .right-layout {
        margin-left: @collapsed-sider-width;

        .container {
          width: 100%;
          margin: 0 auto;
          @media screen and(min-width: 1200px) {
            max-width: 1140px;
          }
          @media screen and(min-width: 1400px) {
            max-width: 1340px;
          }
        }
      }
    }
  }

  .right-layout {
    margin-left: @collapsed-sider-width + @sidebar-width;
    transition: all 0.2s;
    background-color: @content-bg-color;
    padding-top: 1rem;
  }
  .site-layout-background {
    background-color: #fff;
  }
  // #container {
  .ant-page-header-content {
    padding-top: 0 !important;
    // }
  }
  .pt-1 {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem !important;
  }
}
