#main-spin-container {
  > .ant-spin-nested-loading {
    > div > .ant-spin-spinning {
      position: fixed;
      max-height: unset;
      z-index: 9999999999;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
