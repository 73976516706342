.ant-layout-sider {
  .ant-menu-item,
  .ant-menu-submenu-title {
    svg[class*="fa-"] {
      margin-right: @menu-icon-margin-right;
    }
  }

  &.ant-layout-sider-collapsed {
    .ant-menu-item,
    .ant-menu-submenu-title {
      svg[class*="fa-"] + span {
        max-width: 0;
        display: inline-block;
        opacity: 0;
      }
    }
  }
}
