.profile {
  .change_pasword_profile {
    border-bottom: 1px dashed @light-primary-color;
    .ant-form-vertical .ant-form-item-label > label {
      color: #ff8721;
    }
  }

  .maxheight {
    max-height: 19em;
  }

  .box-shadow .ant-card-head {
    min-height: 30px;
    padding: 10px 20px;
  }

  .box-shadow .ant-card-body {
    padding: 0 5px !important;
    .table_detail {
      .ant-table-content > table > thead > tr > th,
      .ant-table-content > table > tbody > tr > td {
        padding: 10px 5px;
      }
    }
  }

  .ant-card-head-title {
    padding: 0;
  }

  .table_detail .ant-table-container {
    padding: 10px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }
  .avatar {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 600px) {
    .avatar {
      width: 250px;
      height: 250px;
    }
  }
}
