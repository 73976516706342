.ant-tabs {
  &.tabs-margin-bottom-0 {
    .ant-tabs-nav {
      margin-bottom: 0;
      &::before {
        border-bottom: 0;
      }
    }
  }
}
