.auth_login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .ant-typography,
  .ant-typography h5 {
    color: #ff8721;
  }
}

.auth_login_box {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #0090c6;
  box-sizing: border-box;
  border-radius: 0px;
}
.ant-form-horizontal .ant-form-item-label {
  text-align: start;
}
