// Date picker
// .ant-picker {
//   @border-radius: @input-border-radius;
// }
@input-border-color: #0075a0;

.ant-modal {
  .ant-picker-clear {
    background-color: @white;
  }
}

// .input-filter.ant-picker {
//   border-radius: 2px;
//   border-color: @input-border-color;
// }
.ant-picker {
  border-radius: 2px;
  border-color: @input-border-color;
}

// Selector
